import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import Markdown from 'markdown-to-jsx'
import '../../src/index.css'

const PromptPage = () => {
    const [promptText, setPromptText] = useState('');
    const [chatHistory, setChatHistory] = useState([
        {
            type: 'user',
            text: "What are the best practices for preventing common diseases in my chickens?"
        },
        {
            type: 'ai',
            text: "To prevent common diseases in chickens, ensure their living environment is clean, provide clean water, feed them a balanced diet, vaccinate them regularly, and monitor their health closely for any signs of illness."
        },
        {
            type: 'user',
            text: "How can I improve the egg production rate in my poultry farm?"
        },
        {
            type: 'ai',
            text: "Improving egg production in poultry can be achieved by providing a balanced diet rich in protein and calcium, maintaining proper lighting (16 hours of light daily), ensuring the hens have a stress-free environment, and regularly checking for any health issues."
        },
        {
            type: 'user',
            text: "What should I do if my chickens show signs of illness?"
        },
        {
            type: 'ai',
            text: "If your chickens show signs of illness, isolate the affected birds immediately, consult a veterinarian, and review their diet and environment for any potential causes. It's also crucial to keep a close eye on the rest of your flock to prevent the illness from spreading."
        }
    ]);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const predefinedPrompts = [
        "What are the best practices for preventing common diseases in my chickens?",
        "How can I improve the egg production rate in my poultry farm?"
    ];

    const handlePromptClick = (prompt) => {
        setPromptText(prompt);
    };


    const handleSendQuestion = async () => {
        if (promptText.trim() === '') {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter a question.', life: 3000 });
            return;
        }
    
        // Add the user's message to the chat history
        const updatedChatHistory = [...chatHistory, { type: 'user', text: promptText }];
        setChatHistory(updatedChatHistory);
        setPromptText('');
        setLoading(true);
    
        try {
            const response = await axios.post('https://vetapp-api.scan-verify.com/api/ask/', {
                question: promptText,  // This sends the user's question to the API
            });
    
            if (response.status === 201 && response.data) {
                // Add the AI's response to the chat history
                const aiResponse = response.data.response;  // Make sure `response` matches the API's response structure
                setChatHistory([...updatedChatHistory, { type: 'ai', text: aiResponse }]);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.error, life: 3000 });
            }
        } catch (error) {
            console.error('Error sending question:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while sending your question.', life: 3000 });
        } finally {
            setLoading(false);
        }
    };
    
    

    return (
        <div className="flex flex-col items-center justify-center min-h-screen max-w-screen-sm bg-gray-100">
            <Toast ref={toast} />
            <Card className="sm:w-full md:w-1/2 p-3 mx-3 items-center rounded-lg flex flex-col">
                <h1 className='font-bold mb-4 mx-auto text-[#6e6d6d] place-self-center text-2xl'>Ask Vet AI</h1>

                <div className="mb-4 w-full">
                    <h3 className='font-semibold text-[#41bd18]'>Predefined Prompts</h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                        {predefinedPrompts.map((prompt, index) => (
                            <Button key={index} label={prompt} className="p-button-outlined p-button-success" onClick={() => handlePromptClick(prompt)} />
                        ))}
                    </div>
                </div>

                <div className="flex flex-col flex-grow overflow-y-auto mb-4" style={{ maxHeight: '400px' }}>
                    {chatHistory.map((message, index) => (
                        <div key={index} className={`p-2 my-1 rounded-md ${message.type === 'user' ? 'bg-green-200 self-end' : 'bg-gray-200 self-start'}`}>
                            <Markdown className="markdown-content">{message.text}</Markdown>
                            {/* <span>{message.text}</span> */}
                             {/* <div dangerouslySetInnerHTML={{ __html: message.text }} />  Render HTML safely */}
                        </div>
                    ))}
                    {loading && (
                        <div className="p-2 my-1 rounded-md bg-gray-200 self-start">
                            <span>Loading...</span>
                        </div>
                    )}
                </div>

                <div className="w-full">
                    <InputTextarea
                        id="promptText"
                        value={promptText}
                        onChange={(e) => setPromptText(e.target.value)}
                        rows={3}
                        className='border border-[#41bd18] px-4 pt-2 mt-3 w-full'
                        placeholder="Type your question here..."
                        required
                    />
                    <Button label="Send" icon="pi pi-send" className="w-full mt-2 p-button-success text-white font-bold bg-[#41bd18]" onClick={handleSendQuestion} />
                </div>
            </Card>
        </div>
    );
};

export default PromptPage;
