// src/pages/LoginPage.js
import React, { useState,useEffect, useRef } from 'react';
import { Button, InputText, Password, Card } from 'primereact';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../components/InputField';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { FaBackward, FaUser } from 'react-icons/fa';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const load = () => {
      setLoading(true);

      setTimeout(() => {
          setLoading(false);
      }, 2000);
  };

  const toast = useRef(null);

    const showSuccess = (severity,summary,message) => {
        toast.current.show({severity:severity, summary: summary, detail:message, life: 10000});
    }



  const handleLogin = async (e) => {
    e.preventDefault();
    
    setProcessing(true)
    // Ensure email and password are filled
    if (email && password) {
        try {
            // First, call the CSRF cookie endpoint
            await axios.get('https://api.bizipay.ng/sanctum/csrf-cookie');

            // After setting the CSRF cookie, make the POST request to your login endpoint
            const response = await axios.post('http://localhost:8000/api/login', {
                email: email,
                password: password,
            });

            // Get the token from the response
            const token = response.data.token;
            const role = response.data.role;

            // Store the token in local storage
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
            setProcessing(false)     
            if(response.data.success){
                showSuccess('success',"Login",'Login successfully')
                
                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000)
            }else{
                showSuccess('error',"Login",response.data.error)
            }

            // Redirect to the dashboard
            
        } catch (error) {
            setProcessing(false)     
            // Handle errors here (e.g., show an error message)
            showSuccess('error',"Login","Login failed: Invalid credentials")
            console.error('Login failed:', error);
        }
    } else {
        showSuccess('error',"Login","Please enter both email and password.")
      
    }
};

useEffect(() => {

    // localStorage.setItem('token',"9|uNcizYdWEBr85RAasqTWNz5cKFCuQKRGTYiOSaeOe4f57c9b")
    const token = localStorage.getItem('token');

    if (token) {

      window.location.href="/dashboard"

    }else{
        navigate('/login');
    }

  }, []);

  return (
    <>
       
   
    <div className="flex items-center justify-center min-h-screen px-3  bg-gray-100">
    <Toast ref={toast} />
      <Card className="sm:w-full md:w-1/2 p-3 shadow-md mx-3 items-center rounded-md ">
       <h1 className='font-bold mb-4 mx-auto text-[#6e6d6d]place-self-center text-2xl'>Login</h1>
        <form onSubmit={handleLogin}>
          <div className="p-fluid">           

          <div className="p-field mb-4">
              <label htmlFor="email" className='font-bold text-[#41bd18]'>Email</label>
              <InputField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={"Enter your email"}
                required
              />
            </div>

            <div className="p-field mb-4">
              <label htmlFor="email" className='font-bold text-[#41bd18]'>Password</label>
                    <InputField
                        id="currentPassword"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder={"Enter your current password"}
                        required
                    />
            </div>
          
      
            <div className="card flex flex-wrap justify-content-center gap-3">
                        <button
                        onClick={handleLogin}
                                type="submit"
                                disabled={processing}
                                className={`hover:shadow-form grid grid-cols-2 w-full items-center rounded-md justify-content-center py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
                            >
                                {processing && (
                                    <div className="items-center">
                                        <ProgressSpinner style={{ width: '30px', height: '30px', color: 'red' }} strokeWidth="6" fill="none" animationDuration=".2s" />
                                    </div>
                                )}
                                <div className="items-center justify-center text-white">
                                Sign In
                                </div>
                        </button>
                </div>           

                <div className="card flex flex-wrap justify-content-center gap-3">
                    <span className='text-1xs mt-2 text-[#6e6d6d]'>Don't have an account with us?. sign up here <Link to="/register" className='text-[#1b3caa]'>Signup</Link></span>
                </div>
          </div>
        </form>
      </Card>
    </div>
    </>
  );
};

export default LoginPage;
