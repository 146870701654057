import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import AddProduct from './pages/AddProduct';
import ViewProducts from './pages/ViewProducts';
import AddStock from './pages/AddStock';
import ViewStocks from './pages/ViewStocks';
import ViewShops from './pages/ViewShops';
import ProductItem from './pages/ProductItem';
import Customer from './pages/Customers';
import UsersForm from './pages/UsersForm';
import ShopProduct from './pages/ShopProducts';
import SalesForm from './pages/SalesForm';
import UploadNetSal from './pages/UploadNetSal';
import Repayment from './pages/Repayment';
import LoginPage from './pages/auth/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import Preloader from './components/Preloader';
import { LoadingProvider } from './context/LoadingContext';
import SalesView from './pages/SalesView';
import Receipt from './pages/Receipt';
import Verify from './pages/Verify';
import VerifyView from './pages/VerifyViews';
import ShopStocks from './pages/ShopStocks';
import StoreStocks from './pages/StoreStocks';
import Register from './pages/auth/Register';
import PromptPage from './pages/PromptPage';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  return (


      <Router>
       <Layout>
        {loading && <Preloader />} {/* Show Preloader while loading */}
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<PromptPage />} />

           
            <Route path="/store/stocks" element={<ProtectedRoute><StoreStocks/></ProtectedRoute>} />
        </Routes>
        </Layout>
      </Router>

  );
}

export default App;
