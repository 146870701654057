import React from 'react';

const Footer = () => {
    return (
        <footer className='w-full bg-[#41bd18] py-4 flex justify-center items-center mt-auto'>
            {/* <p className='text-white'>© 2024 Jigawa Paliative Shops Management System</p> */}
        </footer>
    );
};

export default Footer;
