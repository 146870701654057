// src/pages/Register.js
import React, { useState, useEffect, useRef } from 'react';
import { Button, InputText, Password, Card } from 'primereact';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../components/InputField';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [farmName, setFarmName] = useState('');
    const [numberOfBirds, setNumberOfBirds] = useState('');
    const [poultryBreed, setPoultryBreed] = useState('');
    const [farmLocation, setFarmLocation] = useState('');
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);

    const toast = useRef(null);

    const showSuccess = (severity, summary, message) => {
        toast.current.show({ severity: severity, summary: summary, detail: message, life: 10000 });
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        setProcessing(true);

        // Ensure all fields are filled and passwords match
        if (email && password && confirmPassword && name && farmName && numberOfBirds && poultryBreed && farmLocation) {
            if (password !== confirmPassword) {
                setProcessing(false);
                showSuccess('error', "Registration", "Passwords do not match");
                return;
            }

            try {
                // Call the CSRF cookie endpoint first
                await axios.get('https://api.bizipay.ng/sanctum/csrf-cookie');

                // Make the POST request to your register endpoint
                const response = await axios.post('http://localhost:8000/api/register', {
                    name: name,
                    email: email,
                    password: password,
                    farm_name: farmName,
                    number_of_birds: numberOfBirds,
                    poultry_breed: poultryBreed,
                    farm_location: farmLocation,
                });

                if (response.data.success) {
                    showSuccess('success', "Registration", 'Account created successfully');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    showSuccess('error', "Registration", response.data.error);
                }
            } catch (error) {
                setProcessing(false);
                showSuccess('error', "Registration", "Registration failed");
                console.error('Registration failed:', error);
            }
        } else {
            showSuccess('error', "Registration", "Please fill in all fields.");
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, []);

    return (
        <>
            <div className="flex items-center justify-center min-h-screen px-3 bg-gray-100">
                <Toast ref={toast} />
                <Card className="sm:w-full md:w-1/2 p-3 shadow-md mx-3 items-center rounded-md ">
                    <h1 className='font-bold mb-4 mx-auto text-[#6e6d6d] place-self-center text-2xl'>Signup</h1>
                    <form onSubmit={handleRegister}>
                        <div className="p-fluid">
                            <div className="p-field mb-4">
                                <label htmlFor="name" className='font-bold text-[#41bd18]'>Full Name</label>
                                <InputField
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    placeholder={"Enter your full name"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="email" className='font-bold text-[#41bd18]'>Email</label>
                                <InputField
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder={"Enter your email"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="password" className='font-bold text-[#41bd18]'>Password</label>
                                <InputField
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    placeholder={"Enter your password"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="confirmPassword" className='font-bold text-[#41bd18]'>Confirm Password</label>
                                <InputField
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    type="password"
                                    placeholder={"Confirm your password"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="farmName" className='font-bold text-[#41bd18]'>Farm Name</label>
                                <InputField
                                    id="farmName"
                                    value={farmName}
                                    onChange={(e) => setFarmName(e.target.value)}
                                    type="text"
                                    placeholder={"Enter your farm name"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="numberOfBirds" className='font-bold text-[#41bd18]'>Number of Birds</label>
                                <InputField
                                    id="numberOfBirds"
                                    value={numberOfBirds}
                                    onChange={(e) => setNumberOfBirds(e.target.value)}
                                    type="number"
                                    placeholder={"Enter the total number of birds"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="poultryBreed" className='font-bold text-[#41bd18]'>Poultry Breed</label>
                                <InputField
                                    id="poultryBreed"
                                    value={poultryBreed}
                                    onChange={(e) => setPoultryBreed(e.target.value)}
                                    type="text"
                                    placeholder={"Enter the breed(s) of your poultry"}
                                    required
                                />
                            </div>

                            <div className="p-field mb-4">
                                <label htmlFor="farmLocation" className='font-bold text-[#41bd18]'>Farm Location</label>
                                <InputField
                                    id="farmLocation"
                                    value={farmLocation}
                                    onChange={(e) => setFarmLocation(e.target.value)}
                                    type="text"
                                    placeholder={"Enter your farm location"}
                                    required
                                />
                            </div>

                            <div className="card flex flex-wrap justify-content-center gap-3">
                                <button
                                    type="submit"
                                    disabled={processing}
                                    className={`hover:shadow-form grid grid-cols-2 w-full items-center rounded-md justify-content-center py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
                                >
                                    {processing && (
                                        <div className="items-center">
                                            <ProgressSpinner style={{ width: '30px', height: '30px', color: 'red' }} strokeWidth="6" fill="none" animationDuration=".2s" />
                                        </div>
                                    )}
                                    <div className="items-center justify-center text-white">
                                        Register
                                    </div>
                                </button>
                            </div>

                            <div className="card flex flex-wrap justify-content-center gap-3">
                                <span className='text-1xs mt-2 text-[#6e6d6d]'>Already have an account? <Link to="/login" className='text-[#1b3caa]'>Login here</Link></span>
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
        </>
    );
};

export default Register;
