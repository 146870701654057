// src/components/Layout.js
import React,{useState,useEffect} from 'react';

import SidebarLayout from './SidebarLayout';
import Footer from './Footer';
import  "primereact/resources/themes/arya-green/theme.css";
import BiziAgentLayout from './BiziAgentLayout';
import StakeholderLayout from './StakeholderLayout';
import { useNavigate } from 'react-router-dom';
import ShopAgentLayout from './ShopAgentLayout';
import StoreAgentLayout from './StoreAgent';
import UsersLayout from './UsersLayout';


const Layout = ({ children }) => {
    const [tokens,setToken]= useState(null);
    const [role,setRole]= useState(null);
    const navigate= useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        setToken(token);
        setRole(role);
    }, [navigate]);

    let layoutComponent;

  // Use a switch statement to decide which layout to render
  switch (role) {
    case 'admin':
      layoutComponent = <SidebarLayout />;
      break;

    default:
      layoutComponent = <UsersLayout />; // Render nothing or provide a fallback layout
  }

    return (

              <div className="min-h-screen flex flex-col">
                    <main className="flex-grow">
                        <UsersLayout />
                        <div className=" w-full contents">
                            {children}
                        </div>
                    </main>
            <Footer />
        </div>
    );
};

export default Layout;
